import React, { useEffect } from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";

import "./CPSearchInput.scss";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { translate } from "services/translate";

function CPSearchInput(props: any) {
  const [state, setState] = React.useState<string>("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    setState(val);
    props.getValue(val);
  };

  useEffect(() => {
    props?.value && setState(props?.value);
  }, [props?.value]);

  return (
    <FormControl className={"cp-search-input " + props.className}>
      {props.label && (
        <InputLabel htmlFor="component-helper">
          {props.label + (props.required ? "*" : " ") || "name"}
        </InputLabel>
      )}
      <TextField
        placeholder={props.placeholder || props.label}
        variant="outlined"
        // defaultValue={state}
        autoComplete="off"
        value={state}
        InputProps={{
          startAdornment: (
            <InputAdornment className="cp-search-input-icon" position="start">
              {props.icon ?? <SearchIcon />}
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        helperText={
          state.length > 0 &&
          state.length < 3 &&
          props.charLength > 0 &&
          `${props.charLength || ""} ${translate(
            props.errMessage || "characterLengthMsg"
          )}`
        }
      />
    </FormControl>
  );
}

export default CPSearchInput;
