import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { translate } from "services/translate";
import { AlertTitle } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CPToast(props: any) {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("success");

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (props.keepOnClickAway && reason === "clickaway") {
      return;
    }
    setOpen(false);
    if (props.getValue) props.getValue(false);
  };

  useEffect(() => {
    setType(props.type);
    setOpen(props.open);
  }, [props]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }} style={{ position: "absolute" }}>
      <Snackbar
        anchorOrigin={
          props.position || { vertical: "bottom", horizontal: "right" }
        }
        open={open}
        autoHideDuration={props.timer || 3000}
        onClose={handleClose}
        {...props}
      >
        <div>
          {type === "success" && (
            <Alert onClose={handleClose} severity="success">
              <AlertTitle>{translate("success")}</AlertTitle>
              {props.message || translate("This is a success message!")}
            </Alert>
          )}
          {type === "error" && (
            <Alert onClose={handleClose} severity="error">
              <AlertTitle>{translate("error")}</AlertTitle>
              {props.message || translate("This is an error message!")}
            </Alert>
          )}
          {type === "warning" && (
            <Alert onClose={handleClose} severity="warning">
              <AlertTitle>{translate("warning")}</AlertTitle>
              {props.message || translate("This is a warning message!")}
            </Alert>
          )}
          {type === "info" && (
            <Alert onClose={handleClose} severity="info">
              <AlertTitle>{translate("info")}</AlertTitle>
              {props.message || translate("This is an information message!")}
            </Alert>
          )}
        </div>
      </Snackbar>
    </Stack>
  );
}
