import React from "react";
import { TextField, InputLabel, FormControl } from "@mui/material";

import "./InputText.scss";
import { translate } from "services/translate";

function InputText(props: any) {
  const [state, setState] = React.useState({
    [props.id || "value"]: "",
    isValid: false,
    isTuched: false,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    let newState = {
      [props.id || "value"]: val,
      isValid: props.required ? (val.length > 0 ? true : false) : true,
      isTuched: true,
    };
    setState(newState);
    props.getValue(newState);
  };

  React.useEffect(() => {
    setState({ ...props.value });
  }, [props]);

  //   React.useEffect(() => {
  //     setState(props.value);
  //   }, [props]);

  // const [isValid, setIsValid] = React.useState(true);

  // const [value, setValue] = React.useState(props.value);
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  // 	var value = event.target.value;
  // 	setValue(value);
  // 	props.getValue(value);
  // 	if (value.length > 3) setIsValid(isEmail(value));
  // };

  return (
    <FormControl className={"input " + props.className}>
      <InputLabel htmlFor="component-helper">
        {props.label + (props.required ? "*" : " ") || "name"}
      </InputLabel>
      <TextField
        required={props.required ? true : false}
        disabled={props.disabled ? true : false}
        error={state.isTuched && !state.isValid}
        placeholder={props.placeholder || props.label}
        id={props.id ? props.id : "input-secret-id"}
        variant="outlined"
        value={state[props.id || "value"]}
        autoComplete={props.autoComplete || "off"}
        // inputProps={{
        //   autoComplete: props.id ? props.id : "input-secret-id",
        // }}
        helperText={
          state.isTuched && !state.isValid
            ? translate("Enter more than 1 characters")
            : ""
        }
        onChange={handleChange}
        multiline={props.multiline || false}
      />
    </FormControl>
  );
}

export default InputText;
