import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./ActivateAccount.scss";

import { Box, Container, Link } from "@mui/material";

//Components

import DefaultButton from "../../components/shared/default-button/DefaultButton";

// Services
import { LoginHttpServices } from "../../services/login";
import { translate } from "services/translate";
import CPToast from "components/shared/cp-toast/CPToast";

interface Location {
  state: {
    from: {
      pathname: string;
    };
  };
}
function ActivateAccount() {
  let navigate = useNavigate();
  const [token, setToken] = useState<any>();
  const [waitCoupleOfSeconds, setWaitCoupleOfSeconds] = useState(true);
  const [email, setEmail] = useState<any>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [toast, setToast] = React.useState({
    open: false,
    type: "success",
    message: "success",
    timer: 30000,
  });

  useEffect(() => {
    console.log("sign Up Search Params", searchParams.get("activationToken"));
    let tempToken = searchParams.get("activationToken") || "";
    setToken(searchParams.get("activationToken"));

    // If no token provided, let them know whats going on and redirect them to login
    if (tempToken.length <= 1) {
      setToast({
        ...{
          open: true,
          type: "error",
          message: translate("No Token Available"),
          timer: 30000,
        },
      });
      //   setTimeout(() => {
      //     // navigate("/reset-password", {
      //     //   replace: true,
      //     // });
      //   }, 4000);
    } else {
      setTimeout(() => {
        setWaitCoupleOfSeconds(false);
      }, 4000);
    }
  }, []);

  //   const location = useLocation() as Location;

  // Submit Form and get tokenS
  const submit = async function () {
    setWaitCoupleOfSeconds(true);
    const isLogin = await LoginHttpServices.activateAccount(token).then(
      (res) => {
        if (res.data?.hasError) {
          console.log(res.data.errorCode === 406);
          if (res.data.errorCode === 406) {
            setToast({
              ...{
                open: true,
                type: "error",
                message: translate("accountActivatedBefore"),
                timer: 15000,
              },
            });
            setTimeout(() => {
              navigate("/forget-password", {
                replace: true,
              });
            }, 15000);
          } else {
            setToast({
              ...{
                open: true,
                type: "error",
                message: res.data?.message || translate("Invalid Token"),
                timer: 30000,
              },
            });
          }

          setWaitCoupleOfSeconds(false);
        } else {
          setEmail(res.data.message);
          setTimeout(() => {
            navigate("/reset-password", {
              replace: true,
            });
          }, 20000);
        }
      }
    );
  };

  return (
    <div className="cp-activate-account-container">
      <Container
        className="cp-activate-account-content cp-activate-account-flex"
        component="section"
      >
        <div className="cp-activate-container">
          <img src={window.location.origin + "/images/Logo.png"} alt="" />
          <h1 className="cp-activate-text">{translate("activateAccount")}</h1>
          <p className="cp-activate-desc" style={{ marginBottom: "5px" }}>
            {translate("activateAccountDesc")}
          </p>
          <p className="cp-activate-desc" style={{ marginTop: "5px" }}>
            {translate("afterActivateDesc")}
          </p>
        </div>

        <Box
          className="cp-activate-account-form"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={submit}
        >
          <div className="cp-activate-account-btn">
            <DefaultButton
              label={translate("activateBtn")}
              onClick={submit}
              disabled={waitCoupleOfSeconds}
            ></DefaultButton>
            {/* {!authIsValid && (
              <p className="cp-auth-failed">
                Username or password is incorrect
              </p>
            )} */}
          </div>

          {/* <p className="email-us">
            Don’t have an account?
            <Link
              className="cp-activate-account-link"
              href="#"
              underline="none"
              color="#130F26"
            >
              Email Us
            </Link>
          </p> */}
        </Box>
        <div className="flex-item cp-activate-account-options">
          {email && (
            <>
              <p className="cp-activate-account-message-success">
                {translate("accountActivated")} ({email})
              </p>
              <p className="cp-activate-account-message-description">
                {translate("redirectToSetPassword")}
                <Link
                  className="cp-activate-account-link"
                  href="/reset-password"
                  underline="none"
                  color="#130F26"
                >
                  {translate("clickHere")}
                </Link>
              </p>
            </>
          )}
        </div>
        <span className="tentamus-copyright">
          © Tentamus {new Date().getFullYear()}
        </span>
      </Container>

      <section className="cp-activate-account-flex cp-activate-account-image">
        <img
          src={window.location.origin + "/images/login-img.png"}
          alt=""
        ></img>
      </section>
      <CPToast
        open={toast.open}
        type={toast.type}
        message={toast.message}
        timer={toast.timer || 30000}
        getValue={(value: any) =>
          setToast({
            ...{
              open: false,
              type: "",
              message: "",
              timer: 30000,
            },
          })
        }
      />
    </div>
  );
}

export default ActivateAccount;
