import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import GlobalProfileServices from "services/profile";

// Icons
import { ReactComponent as DashboardIcon } from "../assets/images/svg/dashboard.svg";
import { ReactComponent as OrdersIcon } from "../assets/icons/archive.svg";

import { ReactComponent as SampleSubmissionIcon } from "../assets/images/svg/samples.svg";
import { ReactComponent as ResultsIcon } from "../assets/images/svg/results.svg";
// import { ReactComponent as DocumentsIcon } from "../assets/images/svg/document.svg";
import { ReactComponent as InvoicesIcon } from "../assets/images/svg/invoice.svg";
import { ReactComponent as TrendingIcon } from "../assets/images/svg/piechart.svg";
import { ReactComponent as QualityIcon } from "../assets/images/svg/clock.svg";
import { ReactComponent as SampleRegIcon } from "../assets/images/svg/add-circle.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/svg/logout.svg";
// import { ReactComponent as HelpCenterIcon } from "../assets/images/svg/help.svg";
import { ReactComponent as IsLoadingIcon } from "../assets/images/LoadingTentamus.svg";
import { ReactComponent as AddIcon } from "assets/icons/plus-circle.svg";

import "./PageLayout.scss";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import CPLogoutModal from "components/shared/cp-logout-modal/CPLogoutModal";
import { translate } from "services/translate";
import CPAuthorityGuard from "layouts/AuthorityGuard/AuthorityGuard";
import CPLoadingScreen from "components/shared/cp-loading-screen/CPLoadingScreen";
import Utils, { routeHistory } from "services/Utils";
import useResolution from "hooks/useResolution";
import BurgerMenu from "layouts/Footer/BurgerMenu";
import CPSearchInput from "components/shared/search-input/CPSearchInput";
import DefaultButton from "components/shared/default-button/DefaultButton";
import OutlinedButton from "components/shared/outlined-button/OutlinedButton";

function PageLayout() {
  const [profile, setProfile] = useState<any>();
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const location = useLocation() as any;
  const [loading, setLoading] = useState<boolean>(true);
  const [links, setLinks] = useState<any>([]);
  const history = useRef({
    cur: "",
    prev: "",
  });

  const handleSampleRegister = () => {
    navigate("/sample-reg");
  };

  const resolution = useResolution();

  useMemo(() => {
    const getProfileInfo = function () {
      GlobalProfileServices.getProfileData(true).then((res: any) => {
        setProfile({ ...res });
        setLinks([...links]);
        setLoading(false);
      });
    };
    getProfileInfo();
  }, []);

  useEffect(() => {
    // getProfileInfo();
    if (location?.pathname === "/") {
      navigate("../dashboard");
    }
    const changeActive = links.map((list: any) => {
      if (location.pathname.indexOf(list.link) >= 0) {
        list.selected = true;
        return list;
      } else {
        list.selected = false;
        return list;
      }
    });

    routeHistory.history.prev = routeHistory.history.cur;
    routeHistory.history.cur = location.pathname;

    setLinks(changeActive);
  }, [location]);

  useEffect(() => {
    setLinks([
      {
        name: translate("dashboardMenu"),
        icon: <DashboardIcon />,
        link: "/dashboard",
        selected: true,
        page: "DASHBOARD",
        children: [],
        newCount: 0,
      },
      {
        name: translate("ordersMenu"),
        icon: <OrdersIcon />,
        link: "/orders",
        page: "ORDER",
        selected: false,
        newCount: 10,

        //   children: [
        //     {
        //       name: "Orders ",
        //       icon: <SampleSubmissionIcon />,
        //       link: "/orders",
        //       selected: false,
        //     },
        //     {
        //       name: "Samples ",
        //       icon: <SampleSubmissionIcon />,
        //       link: "/samples",
        //       selected: false,
        //     },
        //   ],
      },
      {
        name: translate("samplesMenu"),
        icon: <SampleSubmissionIcon />,
        link: "/samples",
        page: "SAMPLE",
        selected: false,
        newCount: 8,
      },
      {
        name: translate("resultsMenu"),
        icon: <ResultsIcon />,
        link: "/results",
        selected: false,
        page: "RESULT",
        children: [],
        newCount: 0,
      },
      // {
      //   name: "Documents",
      //   icon: <DocumentsIcon />,
      //   link: "/documents",
      //   selected: false,
      // },
      {
        name: translate("quotesMenu"),
        icon: <QualityIcon />,
        link: "/quotes",
        className: "",
        selected: false,
        page: "QUOTE",
        children: [],
        newCount: 0,
      },
      {
        name: translate("invoicesMenu"),
        icon: <InvoicesIcon />,
        link: "/invoices",
        selected: false,
        page: "INVOICE",
        children: [],
        newCount: 0,
      },
      {
        name: translate("trendingMenu"),
        icon: <TrendingIcon />,
        link: "/trending",
        selected: false,
        page: [
          "STATISTICS_MONEYSPENT",
          "STATISTICS_SCATTERPLOT",
          "STATISTICS_COLUMNCHART",
        ],
        children: [],
        newCount: 0,
      },
      {
        name: translate("orderRegistrationMenu"),
        icon: <SampleRegIcon />,
        link: "/sample-reg",
        selected: false,
        page: "ORDERREGISTRATION",
        children: [],
        newCount: 0,
      },
    ]);
  }, [profile]);

  const menus = (
    <>
      <List className="sidebar-list" key={"cp-sidebar-list"}>
        {resolution === "desktop" && (
          <img
            className="sidebar-logo"
            src={window.location.origin + "/images/Logo-white.png"}
            alt=""
          />
        )}

        {links?.map((item: any, index: any) => (
          <div key={index}>
            <CPAuthorityGuard roles={profile?.roles} page={item?.page}>
              {/* <Link to={item.link}> */}
              <ListItem
                component="a"
                href={item.link || ""}
                className={
                  "sidebar-list-item "
                  //    + (item.children?.length > 0 ? "cp-sidebar-has-child" : "")
                }
                // key={index}
                disablePadding
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (e.ctrlKey !== true) {
                    e.preventDefault();
                    item.link && navigate("../" + item.link);
                  }
                }}
              >
                <ListItemButton
                  className="sidebar-list-item-button"
                  selected={item.selected}
                  //href={item.link}
                >
                  <ListItemIcon className="sidebar-list-item-icon">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar-list-item-text"
                    primary={item.name}
                  />
                  {/* {item.newCount > 0 && (
                          <span className="sidebar-list-notification">
                            {item.newCount < 100 ? item.newCount : "99"}
                          </span>
                        )} */}
                </ListItemButton>
              </ListItem>
              {/* </Link> */}
              {/* {item.children?.length > 0 &&
                item.children.map((child, chidIndex) => (
                  <Collapse
                    in={item.selected}
                    timeout="auto"
                    unmountOnExit
                    className="cp-sidebar-submenu"
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{ minWidth: 30 }}></ListItemIcon>
                        <ListItemText primary={child.name} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))} */}
            </CPAuthorityGuard>
          </div>
        ))}
      </List>
      <Divider />
      <List className="sidebar-options " key={"cp-customer-options"}>
        <ListItem
          component="a"
          href="/profile"
          disablePadding
          key={"cp-sidebar-customer-name"}
          className="cp-user-list-item sidebar-list-item"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            navigate("../profile");
          }}
        >
          <ListItemButton className="sidebar-list-item-button">
            {/* <img
                className="cp-user-image"
                src="/images/Logo-white.png"
                alt=""
              /> */}
            <img
              src={window.location.origin + "/images/avatar.png"}
              className="cp-user-image"
              alt="Client Avatar"
            />
            <Typography component="p">
              {profile?.name || "Leo Baptista"}
            </Typography>
          </ListItemButton>
        </ListItem>
        {resolution === "desktop" && (
          <>
            <ListItem
              disablePadding
              key="cp-sidebar-customer-logout-2"
              className="sidebar-list-item"
              onClick={() => setOpenLogout(true)}
            >
              <ListItemButton
                className="sidebar-list-item-button"
                //href={"login"}
              >
                <ListItemIcon className="sidebar-list-item-icon">
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  className="sidebar-list-item-text"
                  primary={translate("logoutLabel")}
                />
              </ListItemButton>
            </ListItem>
          </>
        )}

        {resolution !== "desktop" && (
          <>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
              }}
            />
            <ListItem
              disablePadding
              key="cp-sidebar-order-register"
              className="sidebar-list-item"
              onClick={() => setOpenLogout(true)}
            >
              <ListItemButton
                className="sidebar-list-item-button-2"
                //href={"login"}
              >
                <CPAuthorityGuard
                  roles={profile?.roles}
                  page={"ORDERREGISTRATION"}
                >
                  <DefaultButton
                    startIcon={<AddIcon />}
                    label={translate("SampleRegistration")}
                    className="btn-sample-register"
                    onClick={handleSampleRegister}
                  />
                </CPAuthorityGuard>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              key="cp-sidebar-customer-logout"
              className="sidebar-list-item"
              onClick={() => setOpenLogout(true)}
            >
              <ListItemButton
                className="sidebar-list-item-button-2"
                //href={"login"}
              >
                <OutlinedButton
                  className="profile-logout-btn"
                  label={translate("logoutLabel")}
                  onClick={() => {
                    setOpenLogout(false);
                    setTimeout(() => {
                      setOpenLogout(true);
                    });
                  }}
                  color="error"
                ></OutlinedButton>
              </ListItemButton>
            </ListItem>
          </>
        )}
        <CPLogoutModal openLogout={openLogout} setOpenLogout={setOpenLogout} />
        {/* <ListItem
            disablePadding
            key={"cp-sidebar-customer-helpcenter"}
            className="sidebar-list-item"
          >
            <ListItemButton className="sidebar-list-item-button" href={"/help"}>
              <ListItemIcon className="sidebar-list-item-icon">
                <HelpCenterIcon />
              </ListItemIcon>
              <ListItemText
                className="sidebar-list-item-text"
                primary={"Help Center"}
              />
            </ListItemButton>
          </ListItem> */}
      </List>
    </>
  );

  return (
    <>
      {resolution !== "desktop" && <BurgerMenu drawerList={menus} />}

      <div className="panel-container">
        {loading === false ? (
          <>
            {resolution == "desktop" && (
              <aside className="sidebar">{menus}</aside>
            )}

            <Suspense
              fallback={<IsLoadingIcon className="cp-pagelayout-loading" />}
            >
              <aside className="cp-content-container">
                <Outlet />
              </aside>
            </Suspense>
          </>
        ) : (
          <CPLoadingScreen />
        )}
      </div>
    </>
  );
}

export default PageLayout;
