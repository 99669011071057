import { useEffect, useState } from "react";

type ResolutionType = "mobile" | "laptop" | "desktop";

const useResolution = (): ResolutionType => {
  const [resolution, setResolution] = useState<ResolutionType>("desktop");

  useEffect(() => {
    if (window.innerWidth <= 768) setResolution("mobile");
    else if (window.innerWidth <= 1024) setResolution("laptop");
    else setResolution("desktop");
  }, [window.innerWidth]);

  function detectWindowSize() {
    if (window.innerWidth <= 768) setResolution("mobile");
    else if (window.innerWidth <= 1024) setResolution("laptop");
    else setResolution("desktop");
  }

  window.onresize = detectWindowSize;
  return resolution;
};

export default useResolution;
