import React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "assets/icons/x.svg";

import "./BurgerMenu.scss";
import CPSearchInput from "components/shared/search-input/CPSearchInput";
import { translate } from "services/translate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

type BurgerMenuProps = {
  drawerList: JSX.Element;
};

function BurgerMenu({ drawerList }: BurgerMenuProps) {
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const navigate = useNavigate();

  const handleSearch = (event: any) => {
    closeDrawer();
    event.preventDefault();
    if (searchQuery.length > 2) navigate(`/search?query=${searchQuery}`);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpen(true);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const DrawerList = drawerList;

  return (
    <header className="cp-burger-menu">
      <div className="cp-burger-menu-header">
        <img
          src={window.location.origin + "/images/Logo.png"}
          alt="Tentamus Logo"
        />
        <Button
          className="cp-burger-menu-btn"
          startIcon={open ? <CloseIcon /> : <MenuIcon />}
          onClick={() => toggleDrawer()}
        ></Button>
      </div>

      <Drawer
        className="cp-burger-menu-drawer"
        open={open}
        onClose={() => closeDrawer()}
      >
        <div
          className="header-search"
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              handleSearch(event);
            }
          }}
        >
          <CPSearchInput
            className="input-header-search"
            placeholder={translate("search")}
            value={searchQuery}
            charLength="3"
            icon={<SearchIcon onClick={handleSearch} />}
            getValue={(data: any) => {
              console.log("search", data);
              if (data.length > 1) {
                setSearchQuery(data);
              }
            }}
          ></CPSearchInput>
          {/* <MiniInputText /> */}
        </div>
        <div onClick={() => closeDrawer()}>{DrawerList}</div>
      </Drawer>
    </header>
  );
}

export default BurgerMenu;
